import React, { Component } from "react";
import seatingChart from "../images/SeatingChart.jpg"

function SeatingChart() {
  return (
    <div>
      <img src={seatingChart} style={{ maxWidth: "100%" }} alt=""></img>
    </div>
  );
}

export default SeatingChart;
