import React, { useState } from "react";

import Table from "react-bootstrap/Table";

import footer_kbhome from "../images/footer/KB_LogoSmall.png";
import footer_contacts from "../images/footer/Footer_contacts.png";
import footer_resources from "../images/footer/Footer_Resources.png";
import { Carousel } from "react-responsive-carousel";

function Footer() {
  //const ImageCollection = [img1, img2, img3, img4, img5];

  const [counter, setCounter] = useState(0);

/*   setInterval(function () {
    counter === 25 ? setCounter(0) : setCounter(counter + 1);
  }, 60000); */

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const images = importAll(
    require.context("../images/sponsors", false, /\.(png|jpe?g|svg)$/)
  );

  const isMobile = window.matchMedia("(max-width: 809px)").matches;


  return (
    <>
    { isMobile && (
    <footer className="footer">

      {localStorage.getItem("isLoggedIn") != null  && (
        <div>
          <Table
            striped
            hover
            size="sm"
            style={{ height: "50px", border: "none" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: "10%",
                    textAlign: "right",
                    paddingTop: "23px",
                  
                    fontSize: "10px",
                  }}
                >
                  <span>Sponsor By:</span>
                </td>
                <td
                  style={{
                    width: "35%",
                    textAlign: "right",
                    paddingTop: "8px",
                    paddingRight: "20px",
                    fontSize: "12px",
                  }}
                >
                     <Carousel
            autoPlay={true} 
            infiniteLoop={true}
            interval="10000"
            transitionTime="1"
            axis="horizontal"
            centerMode={false}
            showThumbs={false}
            renderIndicator={false}
            showIndicators={false}
            showStatus={false}
          >
            {images ? (
              Object.keys(images).sort(() => Math.random() - 0.5)
              .map((key, i) => (
                <div key={key}>
                  <img
                    src={images[key]}
                    style={{ maxHeight: "50px"}} alt=""
                  />
                </div>
              ))
            ) : (
              <div>Images have not been fetched.</div>
            )}
          </Carousel>

              </td>
                <td
                  style={{
                    width: "15%",
                    textAlign: "left",
                    paddingTop: "15px",
                    paddingLeft: "10px",
                  }}
                >
                  <figure>
                    <a href="/contact">
                    <img src={footer_contacts}></img></a>
                    <figcaption style={{ fontSize: "10px" }}>
                      
                      Contacts
                    </figcaption>
                  </figure>
                </td>

                <td
                  style={{
                    width: "15%",
                    textAlign: "left",
                    paddingTop: "15px",
                  }}
                >
                  <figure>
                  <a href="/Resources">
                    <img src={footer_resources}></img></a>
                    <figcaption style={{ fontSize: "10px" }}>
                      {" "}
                      Resources{" "}
                    </figcaption>
                  </figure>
                </td>
                <td
                  style={{
                    width: "15%",
                    textAlign: "left",
                    paddingTop: "9px",
                    paddingLeft: "5px",
                  }}
                >
                  <a href="http://www.kbhome.com">
                    <img src={footer_kbhome}></img>
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </footer>
    )
                }
    </>
  );
}

export default Footer;
