import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import ExchangeLogo from "../images/logos/Collaboration-Exchange-Logo-A.png";

import "../css/exchange.css";
export class Exchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exchangeInfo2: {
        data: {
          "supplier": "Boise Cascade Engineered Wood Products",
        "firstName": "Ron",
        "lastName": "Gurss",
        "title": "National Accounts Manager",
        "email": "rongurss@bc.com",
        "businessPhone": "303-981-8392",
        "cellPhone": "303-981-8392",
        "sponsorshipLevel": "Gold",
        "mondayNightDinner": "PlatinumCEO",
        "tuesdayNight": "Jardin",
        "wednesdayDinner": "Yes",
        "awardsDinnerTable": "3",
        "hotelRequired": "Yes",
        "confirmationNumber": "27144552",
        "checkIn": "10/30/2023 12:00:00 AM",
        "checkOut": "11/2/2023 12:00:00 AM",
        "wednesdayActivity": "spa",
        "spaType": "Custom Massage",
        "spaTime": "12/31/1899 12:00:00 PM",
        "spaLocation": "Encore",
        "golfClubRentals": null,
        "golfBus": null,
        "attendExchange": "Yes",
        "exchanges": {
            "#1-Exchange 11:00 am": "Test1",
            "#2-Exchange 11:18 am": "Test2",
            "#3-Exchange 11:36 am": "Test3",
            "#4-Exchange 11:54 am": "Test4",
            "#5-Exchange 12:09 pm": "Lunch",
            "#6-Exchange 12:49 pm": "Test5",
            "#7-Exchange 1:07 pm": "Test6",
            "#8-Exchange 1:25 pm": "Test7",
            "#9-Exchange 1:43 pm": "Test8",
            "#10-Exchange 1:58 pm": "Test9",
            "#11-Exchange 2:13 pm": "Break",
            "#12-Exchange 2:31 pm": "Test10",
            "#13-Exchange 2:49 pm": "Test11",
            "#14-Exchange 3:07 pm": "Test12",
            "#15-Exchange 3:25 pm": "Test13",
            "#16-Exchange 3:43 pm": "Test14",
            "#17-Exchange 8:00 am": "Test15",
            "#18-Exchange 8:18 am": "Test16",
            "#19-Exchange 8:36 am": "Test17",
            "#20-Exchange 8:54 am": "Test18",
            "#21-Exchange 9:12 am": "Test19",
            "#22-Exchange 9:30 am": "Test20",
            "#23-Exchange 9:48 am": "Test21",
            "#24-Exchange 10:03 am": "Break",
            "#25-Exchange 10:16 am": "Test22",
            "#26-Exchange 10:34 am": "Test23",
            "#27-Exchange 10:52 am": "Test24",
            "#28-Exchange 11:10 am": "Test25",
            "#29-Exchange 11:28 am": "Test26",
            "#30-Exchange 11:46 am": "Test27"
        }
      }},

      exchangeInfo: {
        data: {
          exchanges: {}
        }
      },
      
      attendExchange: JSON.parse(localStorage.getItem("attendeeInfo")).data.attendExchange,

      attendeeEmail: "",
      isMobile: "",
      imageWidth: '',
      imageMarginTop: '',
      textWidth: '50%',
      textPaddingLeft: '10px',
      isDisplayMondayToAttendee: false,
      isDisplayTuesdayToAttendee: false
    }
  }

  

  componentDidMount() {
    this.setState(
      { attendeeEmail: localStorage.getItem("attendeeEmail") },
      () => {
        this.getAttendeeInfo();
      }
    );

    if(localStorage.getItem("isMobile") == "true")
    {
      this.setState({imageWidth: '130px'}, () =>
      {   
        console.log(this.state.imageWidth);
      }
      );
      this.setState({imageMarginTop: '-81px'}, () =>
      {    
        console.log(this.state.imageMarginTop);
      }
      );
      this.setState({textWidth: '70%'}, () =>
      {    
        console.log(this.state.textWidth);
      }
      );
      this.setState({textPaddingLeft: '76px'}, () =>
      {    
        console.log(this.state.textPaddingLeft);
      }
      );     
    }
  
  }

  getAttendeeInfo = async () => {
    await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_ATTENDEE_DATA_URL +
        this.state.attendeeEmail,
      {
        headers: {
          APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          Origin: "*",
          crossorigin: "use-credentials",
          credentials: "include",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((respData) => {


        this.setState({
          exchangeInfo: respData,
          isDisplayMondayToAttendee: this.isDisplayTableToAttendee(respData.data.exchangeDay1),
          isDisplayTuesdayToAttendee: this.isDisplayTableToAttendee(respData.data.exchangeDay2),
          attendExchange: respData.data.attendExchange
        }, () => {
          console.log("Display Monday: ", this.state.isDisplayMondayToAttendee);
          console.log("Display Tuesday: ", this.state.isDisplayTuesdayToAttendee);
          console.log("Attend exchange: ", this.state.attendExchange);
          console.log("attendee info:", JSON.stringify(this.state.exchangeInfo));
        });

      })
      .catch((err) => {
        //alert('error fetching data');
      });
  };

  isDisplayTableToAttendee = (isDisplay) => {
     if(isDisplay === "Yes") {
      return true;
     }

     return false;
  }

  render() {
    const {imageWidth, imageMarginTop, textWidth, textPaddingLeft} = this.state;

    function getExchangeTime(key) {
      var timeString = key.match(/\d{1,2}:\d{2}\s?[ap]m/); 
    
      if (!timeString) {
        return "Invalid time format"; 
      }
    
      var timeParts = timeString[0].split(" ");
      var time = timeParts[0]; 
      var period = timeParts[1]; 
      var [hour, minBegin] = time.split(":").map(Number);
      var minEnd;

      if (key.includes("#5-Exchange"))
        minEnd = minBegin + 40;     
      else if ( key.includes("#24-Exchange")) 
        minEnd = minBegin + 10;
      
      else minEnd = minBegin + 15;
      
      if (minEnd >= 60) {
        minEnd = minEnd - 60;
        hour = hour + 1;
        if (hour === 12) period = period === "am" ? "pm" : "am"; 
        if (hour > 12) hour = hour - 12; 
      }
    
      minEnd = minEnd < 10 ? "0" + minEnd : minEnd;
      var exchangeTime = `${time} - ${hour}:${minEnd} ${period}`;    
      return exchangeTime;
    }
    

    //const obj = this.state.exchangeInfo2;
    const obj = this.state.exchangeInfo;

    let mondayExchanges = Object.keys(obj.data.exchanges)
    .filter((key, index) => index < 16) // Filter for exchanges 1-16
    .map((key) => {
      if (
        key.indexOf("_") === -1 && // Corrected key condition
        obj.data.exchanges[key] !== null &&
        obj.data.exchanges[key] !== "" &&
        !obj.data.exchanges[key].includes("No Scheduled Appointments")
      ) {
        return (
          <tr key={key}>
            <td>{getExchangeTime(key)}</td>
            <td>{obj.data.exchanges[key]}</td>
          </tr>
        );
      }
      return null;
    })
    .filter(Boolean);


    let tuesdayExchanges = Object.keys(obj.data.exchanges) // need this initialization for conditional rendering to work
  .filter((key, index) => index >= 16) 
  .map((key) => {
    if (
      key.indexOf("_") === -1 && 
      obj.data.exchanges[key] !== null &&
      obj.data.exchanges[key] !== "" &&
      !obj.data.exchanges[key].includes("No Scheduled Appointments")
    ) {
      return (
        <tr key={key}>
          <td>{getExchangeTime(key)}</td>
          <td>{obj.data.exchanges[key]}</td>
        </tr>
      );
    }
    return null;
  })
  .filter(Boolean);
    
  let showMondayExchange = this.state.isDisplayMondayToAttendee;
  let showTuesdayExchange = this.state.isDisplayTuesdayToAttendee;

  return (
    <>
      <div className="App">
        <div className="container outer">
          {this.state.attendExchange === "Yes" ? (
            <div>
              <div class="d-flex justify-content-center">
                <img
                  alt=""
                  src={ExchangeLogo}
                  width={imageWidth}
                  //style={{ marginTop: imageMarginTop }}
                />
              </div>
              <div class="d-flex justify-content-center" >
                <p>
                  You are scheduled to attend the Division + Supplier Collaboration
                  Exchange.  Details of who you will be meeting with are listed below.
                </p>
              </div>
        
  
          
            {mondayExchanges.length > 0 && showMondayExchange && (
              <>
                <h4 className="d-flex justify-content-center monday-exhchange">
                  Monday, November 4th
                </h4>
                <Table
                  className="monday-exchange"
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th className="col-6">Exchange Time</th>
                      <th className="col-6">Company</th>
                    </tr>
                  </thead>
                  <tbody>{mondayExchanges}</tbody>
                </Table>
              </>
            )}

          
            {tuesdayExchanges.length > 0 && showTuesdayExchange && (
              <>
                <h4 className="d-flex justify-content-center tuesday-exchange">
                  Tuesday, November 5th
                </h4>
                <Table
                  className="tuesday-exchange"
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th className="col-6">Exchange Time</th>
                      <th className="col-6">Company</th>
                    </tr>
                  </thead>
                  <tbody>{tuesdayExchanges}</tbody>
                </Table>
              </>
            )}
          </div>
          ) : (
            <div
              className="container"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                minHeight: "630px",
              }}
            >
              You do not have any scheduled Division + Supplier Collaboration
              Exchange Appointments
            </div>
          )}
        </div>
      </div>
    </>
  );
  }
}
