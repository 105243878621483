import React, { Component } from "react";
import map from "../images/EncoreSiteMap.png"

function HotelMap() {
  return (
    <div>
      <img src={map} style={{ maxWidth: "100%" }} alt=""></img>
    </div>
  );
}

export default HotelMap;
