import * as React from "react"
import Table from "react-bootstrap/Table";
import "../../css/Schedule.css";
import HotelMap from "../../images/EncoreSiteMap.png"; 
import agenda from "../../images/agenda.png"; 

import dayone from "../../images/DayOne.svg"; 



interface ScheduleTabOneProps {
  dayImage: string;
}

const DayOne: React.FC<ScheduleTabOneProps> = ({ dayImage }) => {


  const isMobile = window.innerWidth <= 768; // You can adjust the pixel size based on your mobile breakpoint
  const width = isMobile ? "400px" : "646px"; // 400px for mobile, original size for desktop
  const height = isMobile ? (1009/ 646) * 400 + "px" : "1009px"; //


    return (
       
      <div className="App">
      <div id="container" style={{ height: "1100px", textAlign: "center" }}>
        <div
          className="container"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignContent: "center",
            minHeight: "1020x",
          }}
        >
        <p>&bull;  <a href={HotelMap} target="_blank">Property Map</a></p>
          <p> 
            <img 
              src={dayImage}
              className="responsive-svg" 
              alt="Agenda"
            />
          </p>
          <p>&bull;  <a href={HotelMap} target="_blank">Property Map</a></p>
        </div>   
      </div> 
    </div>     
                
    )
}
export default DayOne
