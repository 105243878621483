import React, { Component } from "react";
import LaCave from "../../images/restaurantHotel/LaCave.jpg";
import Steakhouse from "../../images/restaurantHotel/SWRestaurant.jpg";
import TheThriller from "../../images/restaurantHotel/TheThriller.PNG";
import Cowboy from "../../images/restaurantHotel/Cowboy.PNG";

import "../../css/receptiondinner.css";

export class Tuesday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: '600px'
    };
  }
  componentDidMount() {
    if(localStorage.getItem("isMobile") == "true"){
      this.setState({width: '350px'}, () =>
      { console.log(this.state.width);}
      );
    };
  }

  render() {
    const {width} = this.state;
  
      return (
        <>
          {this.props.tuesdayNightDinner === "Brahm" && (
            <div className="border">
                <div>
                  <h2>Giddy Up Partners! Welcome to the KB Ranch!</h2>
                  <p>
                <span style={{ fontWeight: "bold" }}>We are thrilled to welcome you to a night of entertainment with the KB Home Leadership Teams. Have you ever wanted to learn how to line dance or make your own leather belt?    Now is the chance! Come dressed in your best Western attire for a night of celebrating with colleagues, friends, and the KB Home family.</span></p>
                <img src={Cowboy} alt="" style={{ width: width}}></img>
              </div>
              <div style={{ marginTop: "2rem" }}>
                <p className="half-height">Location: Brahm Ballroom</p>
                <p className="half-height">From: 6:30 - 9:30 PM</p>
              </div>
            </div>
          )}

        </>
      );
    
  }
}
