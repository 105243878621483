import React, { Component } from "react";
import "../css/Schedule.css";
import { ScheduleTabs } from "./tabs/ScheduleTabs.tsx";
import TabOne from "./tabs/ScheduleTabOne.tsx";
import TabTwo from "./tabs/ScheduleTabTwo.tsx";

export class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isMobile: false,
      value: "tab1",
      setValue: "",
      attendeeInfo: {},
      fontSize: "14px",
      dayOneImage: null, // State to hold DayOne.svg
      dayTwoImage: null, // State to hold DayTwo.svg
    };
  }

  // Fetch method to get DayOne.svg and DayTwo.svg
  async retrievePhotos() {
    const response = await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_IMAGES_URL,
      {
        headers: {
          "APIKEY": process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          "Origin": "*",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    );
    
    const respData = await response.json();
    const dayOneSvg = respData.data.find(image => image.includes("dayone"));
    const dayTwoSvg = respData.data.find(image => image.includes("daytwo"));

    this.setState({
      dayOneImage: dayOneSvg,
      dayTwoImage: dayTwoSvg,
    });
  }

  componentDidMount() {
    // Call the retrievePhotos method to fetch the images
    this.retrievePhotos();

    if (localStorage.getItem("isMobile") == "true") {
      this.setState({ fontSize: "12px" }, () => {
        console.log(this.state.fontSize);
      });
    }
  }

  render() {
    if (localStorage.getItem("isLoggedIn") == null) {
      window.location = "/login";
    }

    const { dayOneImage, dayTwoImage } = this.state; // Destructure the images from state

    const tabs = [
      {
        label: "Agenda",
        Component: TabOne,
        props: { dayImage: dayOneImage }, // Pass DayOne.svg to TabOne
      }
      // ,
      // {
      //   label: "Day Two",
      //   Component: TabTwo,
      //   props: { dayImage: dayTwoImage }, // Pass DayTwo.svg to TabTwo
      // },
    ];

    return (
      <div className="App">
        <div id="container" style={{ height: "1200px" }}>
          <div class="container">
            <div style={{ fontSize: this.state.fontSize }}>
              <ScheduleTabs tabs={tabs} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
