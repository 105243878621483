import React, { Component } from "react";
export class Polling extends Component {
  render() {
    return (
      <div className="App">
        <div
          className="container"
          style={{
            marginTop: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            minHeight: "630px",
          }}
        >
          {" "}
          <div>
            <h3 id="tabelLabel">Audience Response Polling</h3>
  
            <p>
            Throughout the conference you’ll be asked to participate in some audience polling questions – click  {" "}
              <a
                href="https://app.sli.do/event/kCqm4W3dcHhWRcUdNQkHdz"
                target="_blank"
              
              >
                here {" "}
              </a>
              to access the poll.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
