import React, { Component } from "react";



export class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: "850px",
      imageMarginLeft: "0",
      isMobile: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("isMobile")) {
      this.setState({ imageWidth: "370px" }, () => {
        console.log(this.state.imageWidth);
      });
      this.setState({ imageMarginLeft: "0" }, () => {
        console.log(this.state.imageWidth);
      });
    }
  }

  render() {
    return (
      <>
        <div>
          <div style={{ width: "40%" }}>
            <img
             
              style={{
                maxWidth: this.state.imageWidth,
                paddingTop: "20px",
                marginLeft: this.state.imageMarginLeft,
              }}
              alt=""
            ></img>
          </div>
          <br />
          <div>
            <p>
             Loading your activity . . .
            </p>
            <br />
      
          </div>
        </div>
      </>
    );
  }
}
