import React, { Component } from "react";
import EncoreImage from "../images/restaurantHotel/Encore image.jpg";
import "../css/hotelreservation.css";

export class HotelReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotelInfo: {
        data: {},
      },
      attendeeEmail: "",
      width: "800px",
    };
  }

  componentDidMount() {
    this.setState(
      { attendeeEmail: localStorage.getItem("attendeeEmail") },
      () => {
        this.getAttendeeInfo();
      }
    );
    if (localStorage.getItem("isMobile") == "true") {
      this.setState({ width: "400px" }, () => {
        console.log(this.state.width);
      });
    }
  }
  getAttendeeInfo = async () => {
    await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_ATTENDEE_DATA_URL +
        this.state.attendeeEmail,
      {
        headers: {
          APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          Origin: "*",
          crossorigin: "use-credentials",
          credentials: "include",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((respData) => {
        this.setState({ hotelInfo: respData }, () => {
          console.log("attendee info:" + this.state.hotelInfo);
        });
      })
      .catch((err) => {
        //alert('error fetching data');
      });
  };

  render() {
    const { hotelInfo, width } = this.state;
    var firstName = "";
    var lastName = "";
    var confirmationNumber = "";
    var checkInDate = "";
    var checkOutDate = "";
    var hotelRequired = "";
    {
      Object.keys(hotelInfo.data).map((key, i) => (
        <p key={i}>
          {key === "firstName" ? (firstName = hotelInfo.data[key]) : ""};
          {key === "lastName" ? (lastName = hotelInfo.data[key]) : ""};
          {key === "confirmationNumber"
            ? (confirmationNumber = hotelInfo.data[key])
            : ""}{" "}
          ;{key === "checkIn" ? (checkInDate = hotelInfo.data[key]) : ""};
          {key === "checkOut" ? (checkOutDate = hotelInfo.data[key]) : ""};
          {key === "hotelRequired" ? (hotelRequired = hotelInfo.data[key]) : ""};
        </p>
      ));
    }

    return (
      <>
        <div className="App">
          <div id="container" style={{ height: "800px", textAlign: "center" }}>
            <div
              className="container"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                alignContent: "center",
                minHeight: "470px",
              }}
            >
              <div>
                {hotelRequired.toLocaleLowerCase() == "yes" ? (
                  <div>
                    <div>
                      <img
                        src={EncoreImage}
                        style={{ width: width, marginTop: "-15px" }}
                        alt=""
                      ></img>
                    </div>
                    <br />
                    <p>You are scheduled to stay at <a href="https://www.wynnlasvegas.com/" target="_blank">the Encore at the Wynn Las Vegas.</a></p>
                    <p className="half-height">
                      Details for your room confirmation are below:
                    </p>
                    <p>
                      <b>Name:</b> {firstName} {lastName}
                    </p>
                    <table className="hotel-reservation-table">
                      <thead>
                        <tr>
                          <th style={{ padding: "5px" }}>Confirmation #</th>
                          <th style={{ padding: "5px" }}>Check-in</th>
                          <th style={{ padding: "5px" }}>Check-out</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ padding: "5px" }}>
                            {confirmationNumber}
                          </td>
                          <td style={{ padding: "5px" }}>
                            {checkInDate.split(" ")[0]}
                          </td>
                          <td style={{ padding: "5px" }}>
                            {checkOutDate.split(" ")[0]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <p>
                      The KB Home Supplier Conference will be held at the Encore at the Wynn Las Vegas. You have elected to book your own
                      accommodations.
                    </p>
                    <p>
                      If you have any questions,{" "}
                      <a href="/contact">please contact us. </a>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
