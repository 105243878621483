import React, { Component } from "react";
import map from "../images/wellness-notice.jpg"

function WellnessNotice() {
  return (
    <div>
      <img src={map} style={{ maxWidth: "100%" }} alt=""></img>
    </div>
  );
}

export default WellnessNotice;
