import React, {  Component } from "react";

import hooverdam from "../../images/activity/hooverdam.jpg";


export class Hooverdam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: "850px",
      imageMarginLeft: "44px",
      marginLeft: '8%',
      isMobile: false
    };
  }

  componentDidMount() {

    if(localStorage.getItem("isMobile")){
   
      this.setState({imageWidth: '400px'}, () => {
        console.log(this.state.imageWidth)
      });
      this.setState({imageMarginLeft: '0'}, () => {
        console.log(this.state.imageWidth)
      });
      this.setState({marginLeft: '0'}, () => {
        console.log(this.state.marginLeft)
      });
    };

  }


  render() {
    

      return (
        <>
           <div style={{marginLeft: this.state.marginLeft, textAlign: "center"}}>
          <div style={{width: "40%" }}>
                  <img src={hooverdam} style={{maxWidth: this.state.imageWidth, paddingTop: '20px'}} alt=""></img>
            </div>
            <br />
            <div>
              <p>Take a ride through the historic Boulder City where everything started. You’ll make your way to Lake Mead Lookout to take in the panoramic views before you head off to the Hoover Dam Bypass Bridge, a true engineering marvel! You’ll get a one of a kind look at the face of the Hoover Dam from 1,000 feet above! You will have 20-25 minutes to stroll along the top of this monumental structure to get the perfect photo op. This enhanced option includes a stop at the iconic 
                Seven Magic Mountains Art Exhibit!
                </p>

               <p>Please meet in the Encore Main Entrance at 11:30 am.</p> 
               <p style={{marginTop: "-13px"}}>Plan on being off-site for up to 5 hours.</p>             
            </div>
            </div>
        
        </>
      );
    }
  
}
