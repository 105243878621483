import React, { Component } from 'react';
export class Contact extends Component { 


    render() {

        return (            
          <div className="App">
          <div
              className="container"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                alignContent: "center"
              }}
            >
                <br />
                <h5>Need assistance?</h5>
                <h5>KB Home staff are here to help.</h5>
                <br /> 
                <p>Mercedes Elmore:{" "} <span style={{color: 'blue'}}><a href="mailto:melmore@kbhome.com">MHElmore@kbhome.com</a></span></p>                     
                <p>Darryl Lew:{" "} <span style={{color: 'blue'}}><a href="mailto:dlew@kbhome.com">dlew@kbhome.com</a></span></p>
            </div>
          </div>
        );   
    }

}