import React, { Component } from "react";

import GiveMe2 from "../images/logos/GiveMe2Logo.png";

import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';



export class GiveMeTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isMobile: '',
      formWidth: '80%',
      formMarginLeft: '20%',
      categoryWidth: '25%',
      isActive: true,
      

      category: "",
      title: "",
      comment: "",

      firstName: "",
      lastName: "",
      supplier: "",
      email: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setSelectedCategory = this.setSelectedCategory.bind(this);
  }


  handleChange(event) {
    this.setState({ comment: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.category !== "") {
      this.SaveForm();
    } else {
      alert("Please choose a category.");
      event.preventDefault();
    }
  }
  setSelectedCategory(e) {
    this.setState({ category: e.target.value });
  }
  setTitle(e) {
    this.setState({ title: e.target.value });
  }
  componentDidMount() {



    console.log("isMobile:"+ localStorage.getItem("isMobile"));
    if(localStorage.getItem("isMobile")){
      this.setState({ formWidth: '100%' }, () => {
        this.setState({ categoryWidth: '100%' });
        this.setState({ formMarginLeft: '0%' });
      });
    }
    const userObj = JSON.parse(localStorage.getItem("attendeeInfo"));
    this.setState({ firstName: userObj.data.firstName });
    this.setState({ lastName: userObj.data.lastName });
    this.setState({ supplier: userObj.data.supplier },
      () => {
        console.log(this.state.supplier);});
    this.setState({ email: userObj.data.email }, () => {
      console.log(this.state.email);
    }
    );
  }

  hideAlert() {
    this.setState({
      isActive: false,
    });
  }

   SaveForm = async (e) => {

    const { firstName, lastName, supplier, email, category, title, comment } =
      this.state;

    var surveyDataObj = {};
    Object.assign(surveyDataObj, { firstName: firstName });
    Object.assign(surveyDataObj, { lastName: lastName });
    Object.assign(surveyDataObj, { company: supplier });
    Object.assign(surveyDataObj, { email: email });
    Object.assign(surveyDataObj, { category: category });
    Object.assign(surveyDataObj, { title: title });
    Object.assign(surveyDataObj, { comment: comment });

     await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_POST_SURVEYDATA_URL,
      {
        headers: {
          APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          Origin: "*",
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(surveyDataObj),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("form submitted successfully");
        alert(
          "Form submitted successfully! \n" +
            "Please feel free submit another suggestion/comment. Thank you. "
        );    
        window.location.reload();
      })
      .catch((err) => {
        console.log("error fetching data");
      });
  }; 

  render() {
    return (
    <>
      <div className="App">
        <br />
        <div style={{ paddingRight: "5px", textAlign: "center" }}>
          <img src={GiveMe2} alt=""></img>
        </div>
        <br />
        <div id="container" style={{ height: "1000px", marginLeft: this.state.formMarginLeft  }}>
          <div class="container">
            <div>
              <h5>
                Thank you for joining us at the {new Date().getFullYear()} KB Home Strategic Supplier
                Conference.
              </h5>
              <p>
                KB Home believes in building lasting partnerships with our
                suppliers and business partners.
              </p>
              <p>
                We appreciate you taking the time to provide your ideas and
                feedback and look forward to working together on these
                opportunities.
              </p>
            </div>
            <Form style={{width : this.state.formWidth}}
              onSubmit={this.handleSubmit} 
              aria-label="Default select example"
            >
              <Form.Select
                aria-label="Default select example"
                style={{
                  borderColor: "#0d6efd",
                  width: this.state.categoryWidth,
                }}
                onChange={(e) => this.setSelectedCategory(e)} 
              >
                <option value="0" disabled selected>
                  Please select a category:
                </option>
                <option value="Innovation" >Brand</option>
                <option value="Relationships" >Partnership</option>
                <option value="Value">Value</option>
              </Form.Select>
              <br />
              <input
                type="text"
                class="form-control"
                placeholder="Title:"
                style={{width: this.state.categoryWidth}}
                onChange={(e) => this.setTitle(e)} 
              ></input>
              <br />
              <div>
                <textarea
                  placeholder="Please describe your idea or opportunity:"
                  className="form-control"
                  style={{ height: "150px" }}
                 onChange={this.handleChange} 
                />
              </div>
              <br />
              <div>
                <Button variant="outline-primary" type="submit" value="Submit">
                  Submit
                </Button>
              </div>
            </Form> 
          </div>
        </div>
      </div>

      </>
    );
  }
}
