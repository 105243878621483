import React, {  Component } from "react";

import skydiving from "../../images/activity/skydiving.jpg";


export class Skydiving extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: '850px',
      imageMarginLeft: '0%',
      isMobile: false

    };
  }

  componentDidMount() {

    if(localStorage.getItem("isMobile")){

      this.setState({imageWidth: '360px'}, () => {
        console.log(this.state.imageWidth)
      });
      this.setState({imageMarginLeft: '0'}, () => {
        console.log(this.state.imageWidth)
      });
    };

  }


  render() {

    
      return (
        <>
           <div style={{textAlign: "center"}}>
          
          <div style={{width: "40%" }}>
                  <img src={skydiving} style={{maxWidth: this.state.imageWidth, paddingTop: '20px', marginLeft: this.state.imageMarginLeft}} alt=""></img>
            </div>
            <br />
            <div>
              <p>You are scheduled for the Indoor Skydiving. Experience the thrilling sensation of skydiving, without a 
                parachute or airplane, as a 1,000-horsepower motor keeps you soaring through the air in America's
                 original indoor skydiving facility. The propeller turns on and the air begins to flow. You're floating, 
                 flying, free-falling through the air with wind speeds up to 120 mph.</p>
                 <br />
               <p>Please meet at the Tour Bus Entrance at 1:30 pm 

                </p>
            </div>
        </div>
        </>
      );
    }
  
}
