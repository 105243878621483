import React, {  Component } from "react";
import "../../css/receptiondinner.css";
import SeatingChart from "../../images/SeatingChart.jpg";   



export class Wednesday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: '650px'
    };
  }

  componentDidMount() {
   this.getAttendeeInfo();


    if(localStorage.getItem("isMobile") == "true"){
      this.setState({width: '370px'}, () =>
      { console.log(this.state.width);}
      );
    };
 
  }
  getAttendeeInfo = async () => {
     await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_ATTENDEE_DATA_URL +
        localStorage.getItem("attendeeEmail"),
      {
        headers: {
          APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          Origin: "*",
          crossorigin: "use-credentials",
          credentials: "include",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((respData) => {
        this.setState(
          { wednesdayNightDinner: respData.data.wednesdayDinner },
          () => {
            console.log(
              "dinner info:" 
            );
          }
        );
        this.setState(
            { tableNumber: respData.data.awardsDinnerTable },
            () => {
              console.log(
                "dinner table info:" 
              );
            }
          );
      })
      .catch((err) => {
        alert("error fetching data");
      });
  };
  render() {
    return (
        <>
       {this.props.wednesdayNightDinner === "Yes" ? (
          <div className="border">
             <br />
            <p className="font-link-award">
               <b >Awards Gala</b>
            </p>
            <br />
            <p className="half-height">Reception: 6:30 PM</p>
            <p className="half-height">Dinner: 7:30 PM</p>
            <p className="half-height">Table Number: {this.props.tableNumber} </p>
            <p className="half-height"><a href={SeatingChart} target="_blank">Seating Chart</a></p>
            
          </div>
       ):
       (
        <div>Not attending Wednesday Night Dinner</div>
       )
  }
 
        </>
      );
    
  }
}
