import React, { Component } from "react";
import spa from "../../images/activity/spa.png";
import HotelMap from "../../images/EncoreSiteMap.png";
import SpaMapWynn from "../../images/WynnSpaMap.svg";


export class Spa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /* hour: (this.props.spaTime.split(" ")[1]).split(":")[0],
      minute: (this.props.spaTime.split(" ")[1]).split(":")[1], */
      hour: (this.props.spaTime.split(" ")[1]).split(":")[0],
      minute: (this.props.spaTime.split(" ")[1]).split(":")[1].substring(0,2),
      am_pm: this.props.spaTime.split(" ")[2],
      convertHour: '',
      spaLocation: this.props.spaLocation,

      imageWidth: "850px",
      imageMarginLeft: "0",
      isMobile: false,
    };
  }

  componentDidMount() {
  
    if (localStorage.getItem("isMobile")) {
      this.setState({ imageWidth: "400px" }, () => {
        console.log(this.state.imageWidth);
      });
      this.setState({ imageMarginLeft: "0" }, () => {
        console.log(this.state.imageWidth);
      });
    }
  
  }
  render() {
    const { hour, minute, convertHour, spaLocation} = this.state;
   
    return (
      <>
        <div style={{ width: "100%", marginRight: "auto" }}>
          <div style={{ width: "50%" }}>
            <img
              src={spa}
              style={{
                maxWidth: this.state.imageWidth,
                paddingTop: "20px",
                marginLeft: this.state.imageMarginLeft,
              }}
              alt=""
            ></img>
          </div>
          <br />
          <div>
            <table>
              <thead>
                <tr>
                  <th style={{ padding: "5px" }}>Spa Type</th>
                  <th style={{ padding: "5px" }}>Time</th>
                  <th style={{ padding: "5px" }}>Location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "5px" }}>{this.props.spaType != null ? this.props.spaType : "Massage"}</td>
                  <td style={{ padding: "5px" }}>
                { 
               /*    this.state.hour - 12 
                     + ":" + ((this.props.spaTime.split(" ")[1]).split(":")[1])
                    + " PM"  */
                      this.state.hour   + ":" + this.state.minute + " " + this.state.am_pm
                 } 
                 </td>
                  
                  <td style={{ padding: "5px" }}>{this.props.spaLocation}</td>
                </tr>
              </tbody>
            </table>

            { this.props.spaLocation === "Wynn" ? (
              <div>
                <p>
                  <b>The Spa at Wynn</b>{"  "}see <a href={SpaMapWynn} target="_blank">here</a> for the walking
                  directions to The Spa at Wynn. The attached property map will guide you through the Encore Esplanade (the boutique shops). 
                  It takes about 8-10 minutes to reach the Wynn elevators walking through Encore. 
                  Utilize the Wynn hotel elevators to access the spa level for The Spa at Wynn.
                </p>
              </div>
            ):

           (
              <div>
                <p>
                  <b>The Spa at Encore</b> Utilize the Encore hotel 
                  elevators to access the spa level for The Spa at Encore.
                </p>
              </div>
            )}

            <p>
              <b>The Spa at Wynn and The Spa at Encore</b>
            </p>
            <div>
              <ul>
                <li>
                &bull;  Arrival Guidelines: Please arrive 30 minutes prior to the
                  start of your scheduled appointment to allow yourself time to
                  shower and use the lounge, sauna, steam room and whirlpool.
                  Late arrivals will be subject to reduced treatment times.{" "}
                </li>
                <li>
                &bull;  What to Wear: Robes and slippers are provided for your
                  convenience. Professional draping is performed throughout your
                  treatment.{" "}
                </li>
                <li>
                &bull;  Decorum: For the safety and security of our guests and
                  employees, professional and appropriate behavior must be
                  exhibited by all persons at all times.
                </li>
                <li>
                &bull;  Medical Concerns and Comfort Level: Guests who are pregnant,
                  have had recent surgery or have any other medical concerns are
                  advised to consult their physician before booking spa
                  services.{" "}
                </li>
                <li>
                &bull; Cell Phones, Personal Electronic Devices and Cameras: We
                  strive to provide our guests with a tranquil and private
                  escape from the outside world. Please help us by turning off
                  cell phones, personal electronic devices, and cameras upon
                  entering The Spa.{" "}
                </li>
              </ul>

              <p>
                <b>
                  Additionally, note that we do our best to accommodate specific
                  gender requests however these are not guaranteed and based on
                  availability.
                </b>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
