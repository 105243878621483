import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavItem from "react-bootstrap/NavItem";
import NavDropdown from "react-bootstrap/NavDropdown";

import React, { Component , createRef } from "react";
import { NavLink } from "react-router-dom";
import KBHomeLogo from "../images/logos/KB_LogoSmall.png";
import "../css/NavMenu.css";
import Footer from "./footer";

export class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      loggedInUser: "",
      isMobile: "",
      collapseOnSelect: "auto",
      marginBottom: "1%",
      attendExchange: ""
    };
    this.collapseComponent = this.collapseComponent.bind(this);
    this.navbarRef = createRef(); 
  }

  componentDidMount() {
   
    this.checkLastActivityAndLogout();

    if (localStorage.getItem("isLoggedIn") != null) {
      this.setState({ isLoggedIn: localStorage.getItem("isLoggedIn") }, () => {
        console.log(this.state.isLoggedIn);
      });
    }
    if (localStorage.getItem("attendeeName") != null) {
      this.setState(
        { loggedInUser: localStorage.getItem("attendeeName") },
        () => {
          console.log(this.state.loggedInUser);
        }
      );
    }
    if (localStorage.getItem("isMobile") != null) {
      this.setState(
        { isMobile: localStorage.getItem("isMobile") },
        () => {
          console.log(this.state.isMobile);
        }
      );
    }
    if (localStorage.getItem("attendeeInfo") != null) {
      const attendeeInfo = JSON.parse(localStorage.getItem("attendeeInfo"));
      let attendExchange = "No";
      if (attendeeInfo && attendeeInfo.data.attendExchange) {
        attendExchange = attendeeInfo.data.attendExchange;
      }
    
      this.setState(
        { attendExchange: attendExchange },
        () => {
          console.log(this.state.attendExchange);
        }
      );
    }
  }

  checkLastActivityAndLogout = () => {
    const lastActivity = localStorage.getItem("lastActvity");
    if (lastActivity) {
      const lastActivityDate = new Date(lastActivity);
      const currentDate = new Date();
  
      const differenceInTime = currentDate - lastActivityDate;
      const differenceInYears = differenceInTime / (1000 * 3600 * 24 * 365.25); 
  
      // Check if the last activity is not recent
      if (differenceInYears >= .80) {
          // Force logout
          window.location.href = "https://kbhome-sc-sandbox.azurewebsites.net/#/logout";
      } else {
          console.log("User is active.");
      }
  } else {
      console.log("No last activity found.");
  }
  }



  onClick() {
    localStorage.removeItem("attendeeName");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("attendeeEmail");
    localStorage.removeItem("attendeeInfo");
    localStorage.removeItem("isMobile");
    window.location.reload();
  }

  collapseComponent() {
    // Collapse the Navbar
    if (this.navbarRef.current) {
      this.navbarRef.current.classList.remove('show'); // Remove the 'show' class to collapse
    }
  }
  render() {
    const { isLoggedIn, loggedInUser, isMobile, attendExchange } = this.state;
    return (
      <>
        {isLoggedIn ? (
          <div>
            <div style={{ marginBottom: this.state.marginBottom }}>
              <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                  <Navbar.Brand href="" style={{ marginLeft: isMobile ? '0%' : '7%' }}>
                    <span><img
                      src={KBHomeLogo}
                      style={{ maxWidth: "50px" }}
                      alt=""
                    ></img></span>
                    <span classNamesName="app-name">&nbsp;&nbsp;{new Date().getFullYear()}&nbsp;Supplier Conference</span>
                  </Navbar.Brand>

                  <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    style={{ marginLeft: "85%", marginTop: "-14%" }}
                  />
                  <Navbar.Collapse ref={this.navbarRef} id="responsive-navbar-nav">
                    <Nav className="me-auto">
                      <NavItem>
                        <NavLink
                          to="/home"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Home
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/schedule"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Agenda
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/hotel-reservation"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Hotel Reservation
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/reception-dinner"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Meals
                        </NavLink>
                      </NavItem>

                      {attendExchange === "Yes" && (
                        <NavItem>
                          <NavLink
                            to="/exchange"
                            className="nav-link"
                            onClick={this.collapseComponent}
                          >
                            Exchange
                          </NavLink>
                        </NavItem>
                      )}
                      <NavItem>
                        <NavLink
                          to="/activity"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Activity
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/give-me-two"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Give Me 2
                        </NavLink>
                      </NavItem>
                      {/* <NavItem class=''>
                        <NavLink
                          to="/audience-polling"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Polling
                        </NavLink>
                      </NavItem> */}
                      <NavItem>
                        <NavLink
                          to="/photos"
                          className="nav-link"
                          onClick={this.collapseComponent}
                        >
                          Photos
                        </NavLink>
                      </NavItem>
                      
                      <Nav>
                        <NavDropdown title={loggedInUser}>
                          <NavDropdown.Item>
                            <NavLink
                              to="/contact"
                              className="nav-link bg-dark"
                              onClick={this.collapseComponent}
                            >
                              Contacts
                            </NavLink>
                          </NavDropdown.Item>

                          <NavDropdown.Item>
                            <NavLink
                              to="/resources"
                              className="nav-link bg-dark"
                              onClick={this.collapseComponent}
                            >
                              Resources
                            </NavLink>
                          </NavDropdown.Item>

                          <NavDropdown.Item>
                            <NavLink
                              to="/logout"
                              className="nav-link bg-dark"
                              onClick={this.collapseComponent}
                            >
                              Log out
                            </NavLink>
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </div>
        ) : (
          <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Container>
                <Navbar.Brand href="">
                  <img
                    src={KBHomeLogo}
                    style={{ maxWidth: "50px" }}
                    alt=""
                  ></img>
                  &nbsp;&nbsp;{new Date().getFullYear()}&nbsp;Supplier Conference
                </Navbar.Brand>
              </Container>
            </Navbar>
          </div>
        )}
      </>
    );
  }

}
