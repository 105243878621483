import React, {  Component } from "react";

import dunebuggy from "../../images/activity/dunebuggy.png";


export class Dunebuggy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: "850px",
      imageMarginLeft: "44px",
      marginLeft: '8%',
      isMobile: false
    };
  }

  componentDidMount() {

    if(localStorage.getItem("isMobile")){
   
      this.setState({imageWidth: '360px'}, () => {
        console.log(this.state.imageWidth)
      });
      this.setState({imageMarginLeft: '0'}, () => {
        console.log(this.state.imageWidth)
      });
      this.setState({marginLeft: '0'}, () => {
        console.log(this.state.marginLeft)
      });
    };

  }


  render() {
    

      return (
        <>
           <div style={{marginLeft: this.state.marginLeft, textAlign: "center"}}>
          <div style={{width: "40%" }}>
                  <img src={dunebuggy} style={{maxWidth: this.state.imageWidth, paddingTop: '20px', marginLeft: this.state.imageMarginLeft}} alt=""></img>
            </div>
            <br />
            <div>
              <p>You are scheduled for the Dune Buggy Tour. Once you’ve completed the training and safety 
                class you will be all set to ride in a four-seater Dune Buggy for a 2 hour-guided Tour. 
                The tour will take scheduled breaks to allow for drives can switch.
                </p>
                <br />
               <p>Please meet at the Encore Main Entrance at 12:30 pm</p>             
            </div>
            </div>
        
        </>
      );
    }
  
}
