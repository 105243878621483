import "../css/Login.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import WellnessNotice from "../images/wellness-notice.jpg";

function Login() {



  const isMobile = window.matchMedia("(max-width: 809px)").matches;
  var width = '80%'; var marginLeft ='15%'; var marginTop="-60px";
  if (isMobile) {
    width = '98%'; 
    marginLeft ='0%'; 
    marginTop="-6px";

  }

  document.addEventListener("mousemove", () => {
    localStorage.setItem("lastActvity", new Date());
  });
  document.addEventListener("click", () => {
    localStorage.setItem("lastActvity", new Date());
  });

  const navigate = useNavigate();
  const [attendee, setattendee] = useState({ Email: "" });

  useEffect(() => {
    // autoLogin();
  });

  const Login = async (e) => {
    e.preventDefault();
    const response = await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_ATTENDEE_DATA_URL +
        attendee.Email,
      {
        headers: {
          APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          Origin: "*",
          crossorigin: "use-credentials",
          credentials: "include",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    );
    response
      .json()
      .then((respData) => {
        console.log(respData);
        localStorage.setItem("attendeeEmail", attendee.Email);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("attendeeInfo", JSON.stringify(respData));
        localStorage.setItem("attendeeName", respData.data.firstName);
        navigate("/");
        window.location.reload();
      })
      .catch((err) => {
        alert("No information found for: " + attendee.Email);
      });
  };

  const onChange = (e) => {
    console.log(e);
    e.persist();
    setattendee({ ...attendee, [e.target.name]: e.target.value });
  };





  const [show, setShow]        = useState(true);
  const [showForm, setDisplay] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center masthead">
        <form onSubmit={Login} >
          <div className="login-title">
            <p>KB Home National Strategic</p>
            <p>Supplier Conference</p>
            <p>{new Date().getFullYear()}</p>
          </div>
          <div className="login-message"> Login with your email address</div>
          <input
            value={attendee.Email}
            type="email"
            className="form-control"
            onChange={onChange}
            name="Email"
            id="Email"
            required="required"
            aria-describedby="emailHelp"
            placeholder="Enter Email"
          />
          <button type="submit" className="btn btn-primary login-button">
            Login
          </button>
          <div className="cookie-policy">
            <p>
              This web app uses cookies to store your email for quicker access.{" "}
            </p>
            <p>If you have cookies disabled or are using private browsing,</p>
            <p>you will be asked to re-entered your email.</p>
          </div>
        </form>
      </div>
    </>
  );
 }
export default Login;
