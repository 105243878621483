import React, { Component } from "react";
import "../css/resources.css";
import Suppliers from "../images/ListOfSuppliers.jpg";
import HotelMap from "../images/EncoreSiteMap.png";
import ClearCache from "../images/ClearSystemCache.png";

export class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfFiles: {
        data: []
      },
      podcastFiles: {}
    };
  }

   // Fetch PDFs
   async fetchPdfFiles() {
    await fetch(process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_PDF, {
      headers: {
        APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
        Origin: "*",
        "X-Requested-With": "XMLHttpRequest",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((respData) => {
        this.setState({ pdfFiles: respData }, () => {
          console.log("PDF Files:", this.state.pdfFiles);
        });
      })
      .catch((err) => {
        console.log("Error fetching PDF files", err);
      });
  }

  // Fetch Podcasts
  async fetchPodcastFiles() {
    await fetch(process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_PODCASTS, {
      headers: {
        APIKEY: process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
        Origin: "*",
        "X-Requested-With": "XMLHttpRequest",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((respData) => {
        this.setState({ podcastFiles: respData }, () => {
          console.log("Podcast Files:", this.state.podcastFiles);
        });
      })
      .catch((err) => {
        console.log("Error fetching podcast files", err);
      });
  }

 
  async componentDidMount() {
    await this.fetchPdfFiles();
    await this.fetchPodcastFiles();
  }


  render() {
    const { pdfFiles, podcastFiles  } = this.state;


    let powerpointLinks = [];

    let podcastLinks = [];

    if (pdfFiles && pdfFiles.data) {
      pdfFiles.data.forEach((file, index) => {
        //if (file.name.startsWith("ppt")) {
          powerpointLinks.push(
            <tr key={index}>
              <td>
                <p>
                  <span className="bullet">&bull;</span>
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.name}
                  </a>
                </p>
              </td>
            </tr>
          );
        //}
      });
    }
       
    if (podcastFiles && podcastFiles.podcastLinks) {
      Object.entries(podcastFiles.podcastLinks).forEach(([anchorText, hrefPart], index) => {
        podcastLinks.push(
          <tr key={index}>
            <td>
              <p>
                <span className="bullet">&bull;</span>
                <a href={hrefPart} target="_blank" rel="noopener noreferrer">
                  {anchorText}
                </a>
              </p>
            </td>
          </tr>
        );
      });
    }
  

    return (
      <div className="App">
        <div
          className="container"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignContent: "center",
            minHeight: "630px",
          }}
        >
          <h1 id="tabelLabel">Resources</h1>
          <div className="resourceStyle">
            <h5><u>Conference Wifi Details</u></h5>
            <p>&bull; Network: SSID: KB Home Supplier Conference</p>
            <p>&bull; Password: 2024KBHSC</p>
            <br />
                {powerpointLinks.length > 0 && (
                  <div>
                    <p><b>Powerpoint PDFs & Video Links:</b></p>
                    <table>
                      <tbody>{powerpointLinks}</tbody>
                    </table>
                  </div>
                )}

                {podcastLinks.length > 0 && (
                  <div>
                    <p><b>Podcasts:</b></p>
                    <table>
                      <tbody>{podcastLinks}</tbody>
                    </table>
                  </div>
                )}
                <br />
             {/*   <div>
                  <p>&bull; <a href={Suppliers} target="_blank">List of Suppliers</a></p>
                </div> */}
          </div>
        </div>
      </div>
    );
  }
}
