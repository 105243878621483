import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../css/Home.css";

import { Flex } from "rebass";
import { Text } from "rebass";
import { Box } from "rebass";


import schedule from "../images/home/agenda.svg";
import hotel from "../images/home/hotel.svg";
import dinner from "../images/home/meals.svg";

import exchange from "../images/home/exchange.svg";
import photos from "../images/home/photos.svg";

import giveme2 from "../images/home/give-me-two.svg";
import polling from "../images/home/audience-polling.svg";
import activity from "../images/home/activity.svg";
import blank from "../images/home/blank.png";

export class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      isMobile: false,

      attendExchange: JSON.parse(localStorage.getItem("attendeeInfo")).data.attendExchange

    }
  }

  componentDidMount() {
    document.addEventListener("mousemove", () => {
      localStorage.setItem("lastActvity", new Date());
    });
    document.addEventListener("click", () => {
      localStorage.setItem("lastActvity", new Date());
    });

    this.screenTest(window.matchMedia("(max-width: 809px)"));
    var mediaQueryList = window.matchMedia("(max-width: 809px)");
    mediaQueryList.addEventListener("change", this.screenTest);
  }
   screenTest = (e) => {
    if (e.matches) {
      this.setState({ isMobile: true }, () => {
        console.log("Home.js is mobile");
        localStorage.setItem("isMobile", true);
      });
    } else {
      this.setState({ isMobile: false }, () => {
        console.log("Home.js is desktop");
      });
    }
  }; 

  render() {
    const { isMobile, attendExchange } =this.state;

    return (
      <>
        <div className="App">
        {isMobile ? (
          <div class="d-flex justify-content-center">
            <Flex
              style={{
                width: "371px",       
                marginTop: "20px",
                justifyContent: "center"
                
              }}
            >
              <Box width={1 / 3} px={0}>
                <Text p={2} color="black" bg="white" >
                  <Link to="/schedule" className="btn  customMobile">
                    <img className="tiles" src={schedule} alt=""></img>
                
                  </Link>
                </Text>
                
                 { attendExchange == "Yes" &&
                <Text p={2} color="black" bg="white">
                  <Link to="/exchange" className="btn  customMobile">
                    <img className="tiles" src={exchange} alt=""></img>         
                  </Link>
                </Text>
                } 
           
                {/* <Text p={2} color="black" bg="white">
                  <Link to="/audience-polling" className="btn customMobile">
                    <img className="tiles" src={polling} alt=""></img>
                 
                  </Link>
                </Text> */}
              </Box>

              <Box width={1 / 3} px={0}>
                <Text p={2} color="black" bg="white">
                  <Link to="/hotel-reservation" className="btn customMobile">
                    <img className="tiles" src={hotel} alt=""></img>
      
                  </Link>
                </Text>
                <Text p={2} color="black" bg="white">
                  <Link to="/activity" className="btn customMobile">
                    <img className="tiles" src={activity} alt=""></img>
                
                  </Link>
                </Text>
                <Text p={2} color="black" bg="white" className="btn customMobile">
                <Link to="/photos" className="btn  customMobile">
                  <img className="tiles" src={photos} alt=""></img>
                 
                </Link>
              </Text>
                
             
              </Box>

              <Box width={1 / 3} px={0}>
                <Text p={2} color="black" bg="white">
                  <Link to="/reception-dinner" className="btn customMobile">
                    <img className="tiles" src={dinner} alt=""></img>
                   
                  </Link>
                </Text>
                <Text p={2} color="black" bg="white">
                  <Link to="/give-me-two" className="btn  customMobile">
                    <img className="tiles" src={giveme2} alt=""></img>
                  
                  </Link>
                </Text>

              </Box>
            </Flex>
          </div>
        ) : (
          <Flex
            style={{
              width: "575px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "20px",
            }}
          >
            <Box width={1 / 3} px={0}>
              <Text p={2} color="black" bg="white" className="home-tiles">
                <Link to="/schedule" className="btn  customDeskTop">
                  <img className="tiles" src={schedule} alt=""></img>
                </Link>
              </Text>
               { attendExchange == "Yes" &&
              <Text p={2} color="black" bg="white" className="home-tiles">
                <Link to="/exchange" className="btn  customDeskTop">
                  <img className="tiles" src={exchange} alt=""></img>        
                </Link>
              </Text>
              } 
              {/* <Text p={2} color="black" bg="white" className="home-tiles">
                <Link to="/audience-polling" className="btn  customDeskTop">
                  <img className="tiles" src={polling} alt=""></img>          
                </Link>
              </Text> */}
            </Box>

            <Box width={1 / 3} px={0}>
              <Text p={2} color="black" bg="white" className="home-tiles">
                <Link to="/hotel-reservation" className="btn  customDeskTop">
                  <img className="tiles" src={hotel} alt=""></img>
           
                </Link>
              </Text>
              <Text p={2} color="black" bg="white" className="home-tiles">
                <Link to="/activity" className="btn  customDeskTop">
                  <img className="tiles" src={activity} alt=""></img>              
                </Link>
              </Text>
              <Text p={2} color="black" bg="white" className="home-tiles">
                <Link to="/photos" className="btn  customDeskTop">
                  <img className="tiles" src={photos} alt=""></img>
                 
                </Link>
              </Text>
            </Box>

            <Box width={1 / 3} px={0}>
              <Text p={2} color="black" bg="white" className="home-tiles">
                <Link to="/reception-dinner" className="btn customDeskTop">
                  <img className="tiles" src={dinner} alt=""></img>
               
                </Link>
              </Text>
              <Text p={2} color="black" bg="white" className="home-tiles">
                <Link to="/give-me-two" className="btn  customDeskTop">
                  <img className="tiles" src={giveme2} alt=""></img>
                
                </Link>
              </Text>
            </Box>
          </Flex>
        )}
        </div>
      </>
    );
  }
}
