import React, {  Component } from "react";

import digthis from "../../images/activity/digthis.jpg";


export class Digthis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: "850px",
      imageMarginLeft: "44px",
      marginLeft: '8%',
      isMobile: false
    };
  }

  componentDidMount() {

    if(localStorage.getItem("isMobile")){
   
      this.setState({imageWidth: '400px'}, () => {
        console.log(this.state.imageWidth)
      });
      this.setState({imageMarginLeft: '0'}, () => {
        console.log(this.state.imageWidth)
      });
      this.setState({marginLeft: '0'}, () => {
        console.log(this.state.marginLeft)
      });
    };

  }


  render() {
    

      return (
        <>
           <div style={{marginLeft: this.state.marginLeft, textAlign: "center"}}>
          <div style={{width: "40%" }}>
                  <img src={digthis} style={{maxWidth: this.state.imageWidth, paddingTop: '20px'}} alt=""></img>
            </div>
            <br />
            <div>
              <p>America's first and only heavy equipment (Bulldozer or Excavator) playground where YOU'RE in control.
                Re-live your sandbox days of being a child but this time it's for real.  
             
                </p>

               <p>Please meet at the Encore Main Entrance at 1:15 pm.</p>   
               <p style={{marginTop: "-13px"}}>Plan on being off-site for up to 4 hours.</p>               
            </div>
            </div>
        
        </>
      );
    }
  
}
