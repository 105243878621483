import React, {  Component } from "react";

import allegiantstadium from "../../images/activity/allegiant-story-slide4.jpg";


export class AllegiantStadium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: "850px",
      imageMarginLeft: "44px",
      marginLeft: '8%',
      isMobile: false
    };
  }

  componentDidMount() {

    if(localStorage.getItem("isMobile")){
   
      this.setState({imageWidth: '400px'}, () => {
        console.log(this.state.imageWidth)
      });
      this.setState({imageMarginLeft: '0'}, () => {
        console.log(this.state.imageWidth)
      });
      this.setState({marginLeft: '0'}, () => {
        console.log(this.state.marginLeft)
      });
    };

  }


  render() {
    

      return (
        <>
           <div style={{marginLeft: this.state.marginLeft, textAlign: "center"}}>
          <div style={{width: "40%" }}>
                  <img src={allegiantstadium} style={{maxWidth: this.state.imageWidth, paddingTop: '20px'}} alt=""></img>
            </div>
            <br />
            <div>
              <p>Led by expert Allegiant Stadium Hosts, you’ll make your way around the home of the NFL Raiders. You will get the chance to see behind 
                the scenes with exclusive backstage access to the private suites, 
                the broadcast booth, locker rooms, and the UBEO Press Conference Room. 
                After you check out all the behind the scenes fun, you will get the opportunity to step out onto the field!” This enhanced option includes the “VIP Overtime Experience” to play games and 
                enjoy light snacks at the Al Davis Memorial Torch at Coors Landing.
                </p>

               <p>Please meet in the Encore Main Entrance at 12:30 pm.</p>
               <p style={{marginTop: "-13px"}}>Plan on being off-site for up to 2-2.5 hours.</p>                 
            </div>
            </div>
        
        </>
      );
    }
  
}
