import React, { Component } from "react";
import LaCave from "../../images/restaurantHotel/LaCave.jpg";
import Delilah from "../../images/restaurantHotel/Wynn_Delilah_Patio.jpg";

import "../../css/receptiondinner.css";

export class Monday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: '800px'
    };
  }

  componentDidMount() {
    if(localStorage.getItem("isMobile") == "true"){
      this.setState({width: '350px'}, () =>
      { console.log(this.state.width);}
      );
    };
  }

  render() {
    const {width} = this.state;
    if (this.props.mondayNightDinner !== "No") {
      return (
        <>
          <div className="border">
            {this.props.mondayNightDinner === "PurchasingStudio" && (
              <div>
                
                <div>
                  <img src={LaCave} alt="" style={{ width: width }}></img>
                </div>
                
                <p className="margin-top">
                  Purchasing and Studio Leadership Dinner with our partners at ABC Supply, L&W Supply, Daltile and Moen
                </p>
        
                <p className="half-height">Location: La Cave</p>
                <p className="half-height">From 6:00 - 8:00 PM</p>
          
                
              </div>

            )}
            {this.props.mondayNightDinner === "PlatinumCEO" && (
              <div className="margin-top">
                
                <div>
                  <img
                    src={Delilah}
                    alt=""
                    style={{ width: width }}
                  ></img>
                </div>

                <p className="margin-top">
                You are cordially invited to join KB Home's Chairman of the Board, and CEO, Jeffrey T. Mezger,</p>
                <p className="half-height">for a private dinner at Delilah
                </p>

                <p className="half-height">Reception: 5:30 PM</p>
                <p className="half-height">Dinner: 6:15 PM</p>
                
              </div>
            )}
          </div>
        </>
      ) ;
    }
  }
}
