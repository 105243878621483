import React, {useState, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Button from "react-bootstrap/Button";
import userEvent from "@testing-library/user-event";


const Collapsible = (props) => {
  const [open, setOpen] = useState(props.defaultOpen);

  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(open);
  });

  
if(props.label == "Monday, Oct 24th") {
  //alert("open");
}


  return (
    <div>
     
   <Button variant="btn btn-outline" style={{backgroundColor: "#ffc600"}}   onClick={toggle}>{props.label}</Button>
     
   {open &&
  <div className="toggle">{props.children}</div>
  }
    </div>
  );
};
export default Collapsible;
