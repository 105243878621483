import '../css/Login.css';
import React from 'react';


function Logout(props) {  
    localStorage.removeItem("attendeeEmail");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("attendeeInfo");
    localStorage.removeItem("attendeeName");
    localStorage.removeItem("isMobile");
 
    const protocol = window.location.protocol; 
    const hostname = window.location.hostname; 
    window.location.href = `${protocol}//${hostname}`;

    return null; 
}
export default Logout