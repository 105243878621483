import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "../css/Carousel.css";
import Button from "react-bootstrap/Button";
import { FileUploader } from "react-drag-drop-files";
import { v4 as uuidv4 } from 'uuid';

export class Photos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: {
      },
      autoPlay: true,
      files: [],
      setFile: "",
      base64Str: "",
      ip: '',
      email: '',
      newDate: new Date().toLocaleString(),
      width: '80%'
    };
  }
  componentDidMount() {

    if(localStorage.getItem("isMobile") == "true")
    {
      this.setState({width: '95%'}, () =>
      {
        console.log(this.state.width);
      } )
    }
    this.retrieveIP();
    this.retrievePhotos();
  }

  async retrieveIP() {

    const response = await fetch("https://glacial-dawn-70724.herokuapp.com/http://trackip.net/pfsense",
    {  
    })
    response.text()
    .then((respData) => {
     
      //console.log("respData"+(respData.split(":")[1]).split("<")[0]);
      
      this.setState({ ip: (respData.split(":")[1]).split("<")[0]}, () =>
      {
        console.log(this.state.ip);
      })
        this.setState({ email: localStorage.getItem("attendeeEmail")}, () =>
        {
          console.log(this.email);
        })
    })
    .catch((err) => {
      console.log(err);
    });
 
  }


  async retrievePhotos() {
    const response = await fetch(
      process.env.REACT_APP_SUPPLIER_CONFERENCE_GET_IMAGES_URL,
      {
        headers: {
          'APIKEY': process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
          'Origin': "*",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "GET",
      }
    );
    response.json().then((respData) => {
      const filteredImages = respData.data.filter(image => 
        !(image.endsWith('.svg'))
      );
      this.setState({ images: filteredImages }, () => {
        console.log("Filtered images: " + JSON.stringify(this.state.images));
      });
    });
  }

  changeAutoPlay = () => {
    this.setState((state) => ({
      autoPlay: !state.autoPlay,
    }));
  };

 convertToBase64 = async (file) => {
  
    var reader = new FileReader();
    var base64String = '';
    reader.onload = function () {
      base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
    };
    reader.readAsDataURL(file);
    setTimeout(() => {
      this.uploadConvertedFile(base64String, file);
   }, 1000);
  };

  uploadConvertedFile(base64String, file)  {
    
      fetch(process.env.REACT_APP_SUPPLIER_CONFERENCE_UPLOAD_IMAGE_URL,
          {
            headers: {
              'APIKEY': process.env.REACT_APP_SUPPLIER_CONFERENCE_APIKEY,
              'Origin': "*",
               'mode': 'no-cors',
               'Content-Type': 'application/json'       
            },
            method: "POST",
            body: JSON.stringify({
              "fileName": this.state.ip+"_"+this.state.email+"_"+this.state.newDate+".png",
              "base64String": base64String
            })
          }
        )
          .then((respData) => {
            console.log("upload success");
          })
          .catch((err) => {
            console.log("error uploading photo"+err);
          });
        }   
  render() {
    const { images, width} = this.state;
    const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];

    return (
      <>
      <div style={{ height: "1200px" }}>
        <div
          style={{
            marginTop: "2%",
            width: width,
            marginLeft: "auto",
            marginRight: "auto",
            
          }}
        >
          <div>
            <Button
              id="buttonAutoplay"
              variant="outline-primary"
              onClick={this.changeAutoPlay}
              hidden
            ></Button>
          </div>

          {/* width = 98% if mobile */}
          <Carousel
            autoPlay={this.state.autoPlay}
            infiniteLoop={true}
            interval="3000"
            transitionTime="1000"
            axis="horizontal"
            //centerMode={true}
            //centerSlidePercentage="70"
            showThumbs={false}
            showIndicators={false}
           
          >
            {images ? (
              Object.keys(images).map((key, i) => (
                <div key={key}>
                  <img
                    src={images[key].replace(
                      "www",
                      process.env.REACT_APP_SUPPLIER_CONFERENCE_IMAGE_DOMAIN
                    )}
                    className="carousel-img"  
                    alt=""
                  />
                </div>
              ))
            ) : (
              <div>Images have not been fetched.</div>
            )}
          </Carousel>
        </div>

        <div style={{marginRight: 'auto', marginLeft: '10%', marginTop: '1%', width: '70%'}}>
          
          <FileUploader
            handleChange={this.convertToBase64}
            name="file"
            types={fileTypes}
          />
        </div>

        <div className="text-center mt-2 fs-5 text-primary ps-3">
            Please refresh the page after uploading to view the most recent photos.
       </div>

        </div>
      </>
    );
  }
}
