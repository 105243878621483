
import "./App.css";
import React, { Component } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { HashRouter as Router } from 'react-router-dom';
import Login from "./components/login";
import Logout from "./components/logout";

import { Home } from "./components/home";
import { NavMenu } from "./components/navmenu";

import { Schedule } from "./components/schedule";

import { ReceptionDinner } from "./components/receptiondinner";
import { HotelReservation } from "./components/hotelreservation";
import { Resources } from "./components/resources";
import {Activity} from "./components/activity";
import { Exchange } from "./components/exchange";

import { GiveMeTwo } from "./components/givemetwo";

import {Contact} from "./components/contact";
import { Polling } from "./components/polling";
import { Photos } from "./components/photos";
/* import Profile from "./components/profile/profile"; */


import WellnessNotice from "./components/wellnessnotice";
import HotelMap from "./components/hotelmap";
import SeatingChart from "./components/seatingchart";



export default class App extends Component {

  render() {

    const isLoggedIn = (localStorage.getItem("isLoggedIn") != null ? localStorage.getItem("isLoggedIn") : false);

    return (
      <>
        <Router>
          <NavMenu />
          <Routes>
            <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />         
            <Route path="/login" element={isLoggedIn ? <Home /> : <Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/home" element={ isLoggedIn ? <Home /> : <Navigate to="/login" />} />
            <Route path="/schedule" element={ isLoggedIn ? <Schedule /> : <Navigate to="/login" /> } />
            <Route path="/hotel-reservation" element={ isLoggedIn ?<HotelReservation /> : <Navigate to="/login" /> } />
            <Route path="/reception-dinner" element={ isLoggedIn ?<ReceptionDinner /> : <Navigate to="/login" />} />
            <Route path="/activity" element={isLoggedIn ?<Activity /> : <Navigate to="/login"  />} />
             <Route  path="/exchange" element={ isLoggedIn ?<Exchange /> : <Navigate to="/login" />} /> 
            <Route  path="/give-me-two" element={isLoggedIn ?<GiveMeTwo /> : <Navigate to="/login"/> } />

            <Route  path="/contact" element={isLoggedIn ?<Contact /> : <Navigate to="/login" />} />
            <Route  path="/resources" element={isLoggedIn ?<Resources /> : <Navigate to="/login" />} />
            <Route  path="/audience-polling" element={isLoggedIn ? <Polling /> : <Navigate to="/login" />} />
            <Route path="/photos" element={isLoggedIn ? <Photos /> : <Navigate to="/login" />} />
  
            <Route path="/wellnessnotice" element={isLoggedIn ? <WellnessNotice /> : <Navigate to="/login" />} />
            <Route path="/hotelmap" element={isLoggedIn ? <HotelMap /> : <Navigate to="/login" />} />
            <Route path="/seating-chart" element={isLoggedIn ? <SeatingChart /> : <Navigate to="/login" />} />



            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>


      </>
    );
  }
}
